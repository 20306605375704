import { animate, inView, scroll, stagger } from 'motion';
import SplitType from 'split-type';
import Swup from 'swup';

import 'vidstack/bundle';
import 'vidstack/player';
import 'vidstack/player/ui';
import '@styles/app.css';

import createWordTicker from '@components/word-ticker.js';

const swup = new Swup({
    containers: ['#app'],
    linkSelector: 'a[href]:not([target="_blank"])',
    hooks: {
        'content:replace': createAnimations,
    },
});

function createAnimations() {
    document
        .querySelectorAll('h1, h2, h3, .text-xl, .text-center.text-lg')
        .forEach((heading) => {
            const { words } = new SplitType(heading, {
                types: 'words',
            });

            heading.style.visibility = 'hidden';

            const stop = inView(
                heading,
                () => {
                    heading.style.visibility = 'visible';

                    animate(
                        words,
                        {
                            opacity: [0, 1],
                            y: [12, 0],
                        },
                        {
                            delay: stagger(0.02, {
                                startDelay: 0.2,
                                ease: 'easeIn',
                            }),
                            ease: 'easeOut',
                            duration: 0.4,
                        }
                    );

                    stop();
                },
                { margin: '0% 0% -20% 0%' }
            );
        });

    document.querySelectorAll('.prose, .fade').forEach((el) => {
        el.style.opacity = 0;

        const stop = inView(el, () => {
            animate(
                el,
                {
                    opacity: 1,
                },
                {
                    delay: 0.5,
                    duration: 0.6,
                }
            );

            stop();
        });
    });

    document
        .querySelectorAll(
            '.references-grid .grid-item, .images-grid .grid-item'
        )
        .forEach((el) => {
            el.style.opacity = 0;

            const stop = inView(
                el,
                () => {
                    animate(
                        el,
                        {
                            opacity: [0, 1, 1],
                            y: [100, 0],
                        },
                        {
                            duration: 0.75,
                            ease: 'easeOut',
                        }
                    );

                    stop();
                },
                {
                    margin: '0% 0% -33% 0%',
                }
            );
        });

    document.querySelectorAll('.ticker').forEach(createWordTicker);

    const navToggle = document.getElementById('nav-toggle');
    navToggle.addEventListener('click', () => {
        navToggle.ariaExpanded = navToggle.ariaExpanded !== 'true';
    });

    if (typeof ScrollTimeline === 'undefined') {
        if (window.innerWidth <= 768) return;

        document
            .querySelectorAll(
                '.parallax, .references-grid > *, .images-grid > *'
            )
            .forEach((el) => {
                const distance =
                    parseInt(
                        getComputedStyle(el).getPropertyValue('--distance')
                    ) * -2;

                const parallax = animate(
                    el,
                    {
                        translateY: [0, distance],
                    },
                    {
                        ease: 'linear',
                    }
                );

                scroll(parallax);
            });
    }
}

(() => {
    UnicornStudio.init();
    createAnimations();
})();
