import { animate } from 'motion';

const TOTAL_DURATION = 1.8;
const ENTER_DURATION = 0.2;
const EXIT_DURATION = 0.3;

export default function createWordTicker(el) {
    let i = 0;
    const words = el.innerText.slice(1, -1).split('|');

    if (!words.length) return;

    function setWord() {
        el.innerText = words[i];

        i++;

        if (i >= words.length) {
            i = 0;
        }

        setTimeout(transition, TOTAL_DURATION * 1000);
    }

    async function transition(fadeOut = true) {
        if (!document.body.contains(el)) {
            return;
        }

        if (fadeOut) {
            await animate(el, { opacity: 0 }, { duration: EXIT_DURATION });
        }

        setWord();

        animate([
            [
                el,
                { opacity: 1 },
                {
                    duration: ENTER_DURATION,
                },
            ],
            [
                el,
                { '--progress': ['0%', '100%', '100%', '100%'] },
                {
                    duration: TOTAL_DURATION - EXIT_DURATION - ENTER_DURATION,
                    ease: [0.37, 0, 0.63, 1],
                },
            ],
        ]);
    }

    transition(false);
}
